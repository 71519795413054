html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: white;
}

a {
  color: inherit;
  /* text-decoration: none; */
}

* {
  box-sizing: border-box;
}

/* overwriting some details of the default quill theme */
.ql-container {
  border: none !important;
}

.ql-toolbar {
  position: 'fixed';
  border: none !important;
  z-index: 500;
}
